import styled from '@emotion/styled'
import { Component } from 'react'
import { TmrSimpleAttributesMap } from 'stylewhere/api'
import { Box, Icons } from 'stylewhere/components'
import { getCustomTranslation, __CAPITALIZE } from 'stylewhere/shared/utils'

interface Props {
  itemComponent: TmrSimpleAttributesMap[]
  last?: boolean
}

interface State {}

export class ItemComponentRow extends Component<Props, State> {
  state: State = {}

  getDescription = (key, attribute) => {
    if (attribute) {
      return __CAPITALIZE(getCustomTranslation((attribute[key] as any)?.key ?? key))
    }
    return key
  }

  render() {
    const { itemComponent, last } = this.props
    const valuesToExclude = ['creationDate', 'lastModifyDate', 'id', 'itemId', 'attributes']
    const valuesToShow = itemComponent.flat().filter((component) => !valuesToExclude.includes(component.label))

    return (
      <Box vcenter row>
        <Icons.Component width={24} height={24} style={{ marginTop: -7, marginRight: 16 }} />
        <ComponentContainer justify="space-between" flex>
          {valuesToShow.map((value, idx) => (
            <Box style={{ marginBottom: 6 }}>
              <Box row alignSelf="baseline" justify="space-between">
                <LabelText key={value.label}>{this.getDescription(value.label, value)}</LabelText>
                <Code key={value.label}>{value.value}</Code>
              </Box>
              <Separator last={idx === valuesToShow.length - 1} />
            </Box>
          ))}
          <Separator style={{ marginTop: 10, marginBottom: 14 }} last={last} />
        </ComponentContainer>
      </Box>
    )
  }
}

const Separator = styled(Box)<{ last?: boolean }>`
  height: 1px;
  background-color: ${({ last }) => (last ? 'transparent' : '#e0e0e0')};
`
const LabelText = styled.span`
  font-size: 14px;
  line-height: 16px;
  align-items: center;
  height: 22px;
`

const ComponentContainer = styled(Box)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const Code = styled(Box)`
  font-style: normal;
  font-weight: bold;
  font-size: 13;
  line-height: 15px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`
