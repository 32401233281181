import { ProductInfoField, TmrBaseResource, TmrItem, TmrProduct, TmrSimpleAttributesMap } from 'stylewhere/api'

export class AttributeMappings extends TmrBaseResource {
  static endpoint = '/api/v1/attributeMappings' // /profile

  private static getAttributes = async (entity: string) => {
    const attributes = await AttributeMappings.search<TmrSimpleAttributesMap>({
      entity,
      size: 200,
      sort: 'order,asc',
    })
    return attributes.content.filter((attribute) => attribute.visible === true)
  }

  static getProductAttribute = async (product: TmrProduct, fields: ProductInfoField[], exclude: string[]) => {
    let cols: ProductInfoField[] = []
    if (product && product.attributes) {
      const mapping = await AttributeMappings.getAttributes('product')
      if (mapping && mapping.length > 0) {
        for (let r = 0; r < fields.length; r++) {
          cols.push(fields[r])
        }
        for (let r = 0; r < mapping.length; r++) {
          if (exclude.indexOf(mapping[r].code) === -1) {
            cols.push({
              path: 'attributes.' + mapping[r].code + '.value',
              label: mapping[r].code,
            })
          }
        }
      } else {
        cols = fields
      }
    } else {
      cols = fields
    }
    return cols
  }

  static getItemAttribute = async (item: TmrItem) => {
    const mappingItem = await AttributeMappings.getAttributes('item')

    const attrs: TmrSimpleAttributesMap[] = []
    if (mappingItem && mappingItem.length > 0 && item.attributes) {
      for (let r = 0; r < mappingItem.length; r++) {
        attrs.push({
          label: mappingItem[r].code,
          value: item.attributes[mappingItem[r].code] ? item.attributes[mappingItem[r].code].toString() : '---',
        })
      }
    }
    return attrs
  }

  static getItemComponentAttributes = async (item: TmrItem, valuesToExclude: string[] = []) => {
    const mappingItemComponent = await AttributeMappings.getAttributes('itemComponent')

    const attrs: TmrSimpleAttributesMap[][] = []
    if (!item.components) {
      return attrs
    }
    item.components.forEach((itemComponent) => {
      const tmp: TmrSimpleAttributesMap[] = []
      tmp.push(
        Object.entries(itemComponent)
          .filter(([key]) => !valuesToExclude.includes(key))
          .map(([key, value]) => ({ label: key, value }))
      )
      if (
        itemComponent.attributes &&
        Object.keys(itemComponent.attributes).length > 0 &&
        mappingItemComponent.length > 0
      ) {
        mappingItemComponent.forEach((icmap) => {
          tmp.push({
            label: icmap.code,
            value: itemComponent.attributes[icmap.code],
          })
        })
      }
      attrs.push(tmp)
    })
    return attrs
  }
}
