import CSS from 'csstype'
import styled from '@emotion/styled'
import { AnimatePresence, motion, MotionStyle, Transition } from 'framer-motion'
import { BoxAnimations } from 'stylewhere/components'
import { isNumber } from 'stylewhere/utils'

export interface BoxProps {
  flex?: number | boolean | string
  row?: boolean
  center?: boolean
  vcenter?: boolean
  hcenter?: boolean
  hidden?: boolean
  justify?: CSS.Property.JustifyContent
  m?: number | string // margin
  mv?: number | string // margin vertical
  mh?: number | string // margin horizontal
  mt?: number | string // margin top
  mb?: number | string // margin bottom
  mr?: number | string // margin right
  ml?: number | string // margin left
  p?: number | string // padding
  pv?: number | string // padding vertical
  ph?: number | string // padding horizontal
  pt?: number | string // padding top
  pb?: number | string // padding bottom
  pr?: number | string // padding right
  pl?: number | string // padding left
  borderRadius?: number | string
  bgGrey?: boolean
  bg?: string
  height?: string | number
  width?: string | number
  hideBar?: boolean
  onClick?: (item?: any) => void
  children?: React.ReactNode
  style?: MotionStyle
  animation?: 'fade' | 'slide'
  transition?: Transition
  id?: any
  flexWrap?: boolean
  minheight?: string | number
  bb?: string
  textAlign?: string
  alignSelf?: "auto" | "normal" | "center" | "start" | "end" | "self-start" | "self-end" | "flex-start" | "flex-end" | "baseline" | "first baseline" | "last baseline" | "stretch " | "safe center" | "unsafe center" | "inherit" | "initial" | "revert" | "revert-layer" | "unset" 
  maxWidth?: string | number 
}

export function Box(props: BoxProps) {
  const getAnimation = () => {
    switch (props.animation) {
      case 'fade':
        return BoxAnimations.fadeConfig
      case 'slide':
        return BoxAnimations.slideFadeConfig
      default:
        return {}
    }
  }
  return (
    <AnimatePresence key={props.id} custom={{ offsetX: 0, offsetY: 8, reverse: true }}>
      <StyledBox key={props.id} id={props.id} {...props} {...getAnimation()} />
    </AnimatePresence>
  )
}

function flexIfDefined(flex: number | boolean | string | undefined) {
  if (flex === undefined) return undefined
  if (isNumber(flex)) return flex as number
  if (typeof flex === 'string') return flex
  return 1
}

const parseSize = (size: string | number) => {
  if (typeof size === 'string') {
    return size
  }
  return `${size}px`
}

const StyledBox = styled(motion.div)<BoxProps>`
  flex: ${({ flex }) => flexIfDefined(flex)};
  flex-shrink: 0;
  /* flex-basis: auto; */
  ${({ bb }) => bb && `border-bottom: ${bb};`}
  display: ${({ hidden }) => (hidden ? 'none' : '-webkit-flex')};
  ${({ hideBar }) =>
    hideBar &&
    `-ms-overflow-style: none;  /* Internet Explorer 10+ */
     scrollbar-width: none;  /* Firefox */
     ::-webkit-scrollbar { 
       display: none; 
   }
   `}
  flex-direction: ${({ row }) => (row ? 'row' : 'column')};
  ${({ textAlign }) => textAlign && `text-align:${textAlign};`}
  ${({ flexWrap }) => flexWrap && `flex-wrap: wrap;`}
  ${({ hcenter, row }) => hcenter && (row ? 'justify-content:center;' : 'align-items:center;')}
  ${({ vcenter, row }) => vcenter && (row ? 'align-items:center;' : 'justify-content:center;')}
   ${({ center }) => center && 'justify-content:center; align-items:center;'}
   ${({ justify }) => justify && `justify-content:${justify};`}
   ${({ mv }) =>
    mv !== undefined && `margin-top: ${parseSize(mv)} !important; margin-bottom: ${parseSize(mv)} !important;`}
   ${({ mh }) =>
    mh !== undefined && `margin-left: ${parseSize(mh)} !important; margin-right: ${parseSize(mh)} !important;`}
   ${({ mt }) => mt !== undefined && `margin-top: ${parseSize(mt)} !important;`}
   ${({ mb }) => mb !== undefined && `margin-bottom: ${parseSize(mb)} !important;`}
   ${({ mr }) => mr !== undefined && `margin-right: ${parseSize(mr)} !important;`}
   ${({ ml }) => ml !== undefined && `margin-left: ${parseSize(ml)} !important;`}
   ${({ m }) => m !== undefined && `margin: ${parseSize(m)};`}
   ${({ pv }) =>
    pv !== undefined && `padding-top: ${parseSize(pv)} !important; padding-bottom: ${parseSize(pv)} !important;`}
   ${({ ph }) =>
    ph !== undefined && `padding-left: ${parseSize(ph)} !important; padding-right: ${parseSize(ph)} !important;`}
   ${({ pt }) => pt !== undefined && `padding-top: ${parseSize(pt)} !important;`}
   ${({ pb }) => pb !== undefined && `padding-bottom: ${parseSize(pb)} !important;`}
   ${({ pr }) => pr !== undefined && `padding-right: ${parseSize(pr)} !important;`}
   ${({ pl }) => pl !== undefined && `padding-left: ${parseSize(pl)} !important;`}
   ${({ p }) => p !== undefined && `padding: ${parseSize(p)};`}
   ${({ bgGrey, theme }) => bgGrey && `background-color: ${theme.background1};`}
   ${({ height }) => height !== undefined && `height: ${typeof height === 'number' ? `${height}px` : height};`}
   ${({ height }) => height !== undefined && `max-height: ${typeof height === 'number' ? `${height}px` : height};`}
   ${({ minheight }) =>
    minheight !== undefined && `min-height: ${typeof minheight === 'number' ? `${minheight}px` : minheight};`}
   ${({ width }) => width !== undefined && `width: ${typeof width === 'number' ? `${width}px` : width};`}
   ${({ width }) => width !== undefined && `min-width: ${typeof width === 'number' ? `${width}px` : width};`}
   ${({ maxWidth }) => maxWidth !== undefined && `max-width: ${typeof maxWidth === 'number' ? `${maxWidth}px` : maxWidth}`}
   ${({ onClick }) =>
    !!onClick &&
    `cursor: pointer;
   :hover {
    opacity: 0.9;
  };`}
   ${({ bg }) => !!bg && `background-color: ${bg};`}
   ${({ borderRadius }) =>
    !!borderRadius &&
    `border-radius: ${
      (typeof borderRadius === 'number' && `${borderRadius}px`) ??
      (typeof borderRadius === 'string' && `${borderRadius}`)
    };`}
    ${({ alignSelf }) => alignSelf && `aligh-self:${alignSelf};`}
`
