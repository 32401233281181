import { Component } from 'react'
import { ParcelContentEntry, ShippingParcel, Parcels, Asn } from 'stylewhere/api'
import { Box, ParcelRowItemList, ParcelRowStatus, RowProps, SmallTagCounter, StatusChip } from 'stylewhere/components'
import styled from '@emotion/styled'
import { __, T } from 'stylewhere/i18n'
import { OperationConfig, Router } from 'stylewhere/shared'
import { showToastError, hasChecklist, getAsnParcelStatusChip } from 'stylewhere/shared/utils'

interface Props extends RowProps {
  parcel: ShippingParcel
  expandable: boolean
  operation: OperationConfig
  asn?: Asn
}

interface State {
  entries?: ParcelContentEntry[]
  expanded: boolean
  loading: boolean
}

export class ParcelRow extends Component<Props, State> {
  state: State = {
    loading: false,
    entries: undefined,
    expanded: false,
  } as any

  componentDidMount() {
    //this._init()
  }

  _init = () => {
    this.loadContent()
  }

  getLabel = (product) => {
    let label = ''
    if (product) {
      if (
        product.style &&
        ((product.style.description && product.style.description != null) ||
          (product.style.value && product.style.value != null))
      )
        label +=
          product.style.description && product.style.description != null
            ? product.style.description
            : product.style.value
      if (product.material)
        label +=
          (label !== '' ? ' / ' : '') +
          (product.material.description && product.material.description != null
            ? product.material.description
            : product.material.value)
      if (product.color)
        label +=
          (label !== '' ? ' / ' : '') +
          (product.color.description && product.color.description != null
            ? product.color.description
            : product.color.value)
      if (product.size)
        label +=
          (label !== '' ? ' / ' : '') +
          (product.size.description && product.size.description != null ? product.size.description : product.size.value)
    }
    return label !== '' ? label : '---'
  }

  isItems = () => {
    const { operation } = this.props
    return operation.readingsDisplayMode === 'item'
  }

  onClick = () => {
    const { operation, expandable, parcel, asn } = this.props
    if (!expandable) {
      Router.navigate(
        '/shipping/:opCode/asn/:asnId/add/:parcelId',
        { opCode: operation.code, asnId: asn ? asn.id : '', parcelId: parcel.id },
        {
          state: {
            asn: asn,
            parcel,
          },
        }
      )
    } else {
      const { entries, expanded } = this.state
      if (expanded) {
        this.setState({ expanded: false })
      } else {
        if (!entries) {
          this.setState({ loading: true }, this.loadContent)
        } else {
          this.setState({ expanded: true })
        }
      }
    }
  }

  loadContent = async () => {
    try {
      const { operation, parcel } = this.props
      const parcelEntries: ParcelContentEntry[] = await Parcels.parcelContent(
        operation.id,
        parcel.id,
        this.isItems() ? 'item' : 'product'
      )
      this.setState({ expanded: true, entries: parcelEntries, loading: false })
    } catch (e) {
      showToastError('Error in reading the contents of the parcel', __(T.error.error))
    }
  }

  render() {
    const { parcel, expandable, operation } = this.props
    const { loading, expanded, entries } = this.state
    let entryDetectedItems = 0
    let entryUnexpectedItems = 0
    let entryExpectedItems = 0
    if (entries && entries.length > 0) {
      entries.map((e) => {
        entryDetectedItems += e.detected
        entryExpectedItems += e.expected
        entryUnexpectedItems += e.unexpected
      })
    }

    const parcelEntryQuantity =
      parcel.parcelEntryQuantity && parcel.parcelEntryQuantity !== null ? parcel.parcelEntryQuantity : 0
    const parcelUnexpectedQuantity =
      parcel.parcelUnexpectedQuantity && parcel.parcelUnexpectedQuantity !== null ? parcel.parcelUnexpectedQuantity : 0
    const checklistExpectedQuantity = hasChecklist(operation)
      ? parcel.checklistExpectedQuantity && parcel.checklistExpectedQuantity !== null
        ? parcel.checklistExpectedQuantity
        : 0
      : undefined

    const detectedItems =
      entryDetectedItems > 0 && entryDetectedItems !== parcelEntryQuantity ? entryDetectedItems : parcelEntryQuantity
    const unexpectedItems =
      entryUnexpectedItems > 0 && entryUnexpectedItems !== parcelUnexpectedQuantity
        ? entryUnexpectedItems
        : parcelUnexpectedQuantity
    const expectedItems = checklistExpectedQuantity
      ? entryExpectedItems > 0 && entryExpectedItems !== checklistExpectedQuantity
        ? entryExpectedItems
        : checklistExpectedQuantity
      : undefined
    return (
      <Box flex mb={15} pl={0} pr={0}>
        <SessionRow onClick={() => this.onClick()} row vcenter>
          <Box row vcenter flex>
            <Box width={25} vcenter>
              <ParcelRowStatus loading={loading} expandable={expandable} expanded={expanded} />
            </Box>
            <Session row flex vcenter>
              <Box width={350}>{parcel.code}</Box>
              <Box flex={1}>
                <StatusChip status={parcel.status} customStatusType={getAsnParcelStatusChip(parcel.status)} />
              </Box>
              <Box ml={'10px'}>
                <SmallTagCounter
                  alignSelf={'start'}
                  detected={detectedItems}
                  expected={expectedItems}
                  unexpected={unexpectedItems}
                />
              </Box>
            </Session>
          </Box>
        </SessionRow>
        {expanded && expandable && (
          <ParcelRowItemList entries={entries} parcel={parcel} operation={operation} viewHeader={true} />
        )}
      </Box>
    )
  }
}

const SessionRow = styled(Box)`
  border-radius: 10px;
  background-color: #f6f6f6;
  padding: 15px 20px;
  margin-bottom: 10px;
`

const Session = styled(Box)`
  font-weight: 700;
  font-size: 26px;
  line-height: 30px;
  color: #454545;
  margin-left: 7px;
  justify-content: space-between;
`
