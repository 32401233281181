import { api, TmrBaseResource, responseErrorCheck, DecodedParcel, ParcelContentEntry } from 'stylewhere/api'

export class Parcels extends TmrBaseResource {
  static endpoint = 'api/v1/parcels' //logistics

  static async decode(decodePayload: Record<string, any>) {
    const res = await api.post<{ [epc: string]: DecodedParcel }>(
      `api/v1/operations/simpleParcelList/batchDecodeParcelList`,
      decodePayload
    )
    return responseErrorCheck(res)
  }

  static async getById(id: string, requestConfig?) {
    const result = await api.get(`${this.endpoint}/${id}`, requestConfig)
    return responseErrorCheck(result)
  }

  static async parcelContent(operationId: string, parcelId: string, groupedBy: string) {
    const endpoint = `/api/v1/parcels/${parcelId}/parcelContent`
    const result = await api.get<ParcelContentEntry[]>(endpoint, {
      operationId,
      groupedBy,
    })
    return responseErrorCheck(result)
  }
}
