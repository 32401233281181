import { cloneDeep as _cloneDeep } from 'lodash'
import React, { Component } from 'react'
import { DecodedItem, DecodedParcel, Parcels, ShippingParcel, SimpleParcelList } from 'stylewhere/api'
import {
  AntennaButton,
  Box,
  Button,
  DecodedItemsModal,
  GroupedParcelList,
  GroupedParcelListItem,
  Icons,
  Modal,
  Page,
  Section,
  SmallTextCounter,
  Spacer,
  TagCounter,
} from 'stylewhere/components'
import { SimpleParcelListExtensions } from 'stylewhere/extensions'
import { T, __, __UP } from 'stylewhere/i18n'
import {
  AppStore,
  checkRequiredField,
  getDataFromSchema,
  OperationReadingProps,
  OperationReadingProvider,
  OperationReadingState,
  RemoteOperation,
  RfidReader,
  Router,
  SimpleParcelListOperationConfig,
} from 'stylewhere/shared'
import {
  askUserConfirmation,
  closeModal,
  isModalError,
  openModal,
  showToast,
  showToastError,
} from 'stylewhere/shared/utils'

interface State extends OperationReadingState {
  parcelsByAsn: { [asnCode: string]: ShippingParcel[] }
  identifierCodesByParcel: { [parcelId: string]: string[] }
  unexpectedDecodedParcels: { [epc: string]: DecodedParcel }
  ignoredEpcs: any[]
  confirming: boolean
}

//export default class SimpleParcelListReading extends Component<OperationReadingProps<State>, State> {
export default class SimpleParcelListReading<P extends OperationReadingProps<State>, S extends State> extends Component<
  P,
  S
> {
  antennaRef
  matchParams = Router.getMatchParams(this.props)
  locationState = Router.getLocationState<State>(this.props)
  operation = RemoteOperation.getOperationConfig<SimpleParcelListOperationConfig>(this.matchParams.opCode)
  formSchema = SimpleParcelListExtensions.formSchema(this.operation)
  isModal = false
  asnMode: boolean = this.operation.viewMode === 'parcelAndAsn'

  state: S = {
    items: [],
    loading: true,
    formData: this.locationState.formData ?? {},
    parcelsByAsn: {},
    identifierCodesByParcel: {},
    unexpectedDecodedParcels: {},
    ignoredEpcs: [],
    confirming: false,
  } as any

  constructor(props) {
    super(props)
    this.antennaRef = React.createRef()
  }

  async componentDidMount() {
    this.isModal = isModalError(this.operation)
    RfidReader.setAutomaticStop(this.operation.autostopAntennaTimeout > 0)
    RfidReader.setAutomaticStopTime(this.operation.autostopAntennaTimeout)
    OperationReadingProvider.init(this.operation, this.locationState, this.goBack, this.setRfidReaderDecode)
  }

  setRfidReaderDecode = () => {
    RfidReader.setDecodeFunction(this.decodeFunction)
  }

  decodeFunction = async (identifierCodes: string[]) => {
    const { ignoredEpcs } = this.state

    try {
      const decodePayload = getDataFromSchema(this.state.formData, this.formSchema)
      const result = await Parcels.decode({
        identifierCodes,
        operationId: this.operation.id,
        ...decodePayload,
      })
      const { parcelsByAsn, identifierCodesByParcel } = this.state

      for (const tagCode of Object.keys(result)) {
        const decodedParcel = result[tagCode]
        if (!decodedParcel.item || !decodedParcel.parcelCode) {
          ignoredEpcs.push(tagCode)
        } else {
          const asnCode = this.asnMode && decodedParcel.asn ? decodedParcel.asn.code : ''
          if (!parcelsByAsn[asnCode]) parcelsByAsn[asnCode] = []
          const parcel = parcelsByAsn[asnCode]?.find((p) => p.code === decodedParcel.parcelCode)
          if (!parcel) {
            if (decodedParcel.item !== null) {
              const tmp: any = {
                id: decodedParcel.parcelCode,
                code: decodedParcel.parcelCode,
                parcelEntryQuantity: 1,
                __detected: true,
                __quantityRead: 1,
              }
              if (!parcelsByAsn[asnCode]) parcelsByAsn[asnCode] = []
              parcelsByAsn[asnCode].push(tmp)
              identifierCodesByParcel[tmp.id] = identifierCodes
            }
          } else if (parcel && decodedParcel.item !== null) {
            parcel.__detected = true
            parcel.__quantityRead = (parcel.__quantityRead ?? 0) + 1
            if (identifierCodesByParcel[parcel.id]) {
              const tmp: any[] = identifierCodesByParcel[parcel.id]
              identifierCodes.map((identifier) => tmp.push(identifier))
              identifierCodesByParcel[parcel.id] = tmp
            } else identifierCodesByParcel[parcel.id] = identifierCodes
          }
          if (this.asnMode && !!asnCode && asnCode !== '') {
            const asnParcels = (
              await SimpleParcelList.getParcelListByAsn(this.operation, asnCode, 1000, 0, undefined, 'desc', true)
            ).content
            for (const asnParcel of asnParcels) {
              if (!parcelsByAsn[asnCode].find((p) => p.code === asnParcel.code)) {
                parcelsByAsn[asnCode].push({
                  id: asnParcel.code,
                  code: asnParcel.code,
                  parcelEntryQuantity: 1,
                  __detected: false,
                  __quantityRead: 0,
                } as any)
              }
            }
          }
        }
      }

      this.setState({ parcelsByAsn, identifierCodesByParcel, ignoredEpcs })
    } catch (err) {
      showToastError(err, __(T.error.error), this.isModal)
    }
  }

  removeItem = (decodedItem: DecodedItem) => {
    const items = OperationReadingProvider.removeItem(decodedItem, this.state.items)
    this.setState({ items })
  }

  getDetected() {
    return Object.values(this.state.parcelsByAsn)
      .flat()
      .filter(({ __detected }) => __detected)
  }

  getUnexpected() {
    return Object.values(this.state.parcelsByAsn)
      .flat()
      .filter(({ __unexpected }) => __unexpected)
  }

  openRequiredFieldsModal = async (isShowingOnConfirm = false) => {
    try {
      await new Promise((resolve, reject) => {
        AppStore.callbacks.openModalForm(
          resolve,
          this.setState({ confirming: false }),
          isShowingOnConfirm ? __UP(T.misc.update_and_confirm) : __UP(T.misc.confirm),
          __UP(T.misc.back)
        )
      })
    } catch (error) {
      this.setState({ confirming: false })
      return
    }
  }

  onConfirm = async () => {
    this.setState({ confirming: true }, this._confirm)
  }

  _confirm = async () => {
    try {
      if (this.antennaRef && this.antennaRef.current) {
        await this.antennaRef.current.stopReader()
      }
      if (this.operation.showModalOnConfirm || !checkRequiredField(this.state.formData, this.formSchema)) {
        await this.openRequiredFieldsModal(true)
      }
      const confirmData = getDataFromSchema(this.state.formData, this.formSchema)
      await SimpleParcelListExtensions.beforeConfirm(
        this.operation,
        confirmData,
        Object.values(this.state.parcelsByAsn).flat()
      )
      const confirmResult = await SimpleParcelList.save({
        ...confirmData,
        operationId: this.operation.id,
        operationPlaceId: AppStore.defaultWorkstation!.placeId,
        parcelCodes: this.getDetected().map(({ code }) => code),
      })
      await SimpleParcelListExtensions.afterConfirm(this.operation, confirmData, confirmResult)
      showToast({
        title: __(T.misc.success),
        description: __(T.messages.generic_success, { code: this.operation.description }),
        status: 'success',
      })
      this.setState({ confirming: false })
      if (['hideSuccessModal', 'disabled'].includes(this.operation.postConfirmAction)) {
        this.goBack()
      } else {
        if (
          await askUserConfirmation(
            __(T.confirm.post_confirm_action_title),
            this.operation.postConfirmAction === 'keepInput'
              ? __(T.confirm.post_confirm_action_keep_input)
              : __(T.confirm.post_confirm_action_change_input),
            __(T.misc.no),
            __(T.misc.yes)
          )
        ) {
          if (this.operation.postConfirmAction === 'keepInput') {
            this.onClear()
          } else {
            this.goBack()
          }
        } else {
          this.goDashboard()
        }
      }
    } catch (err) {
      this.setState({ confirming: false })
      showToastError(err, __(T.error.error), this.isModal)
    }
  }

  goDashboard = () => {
    Router.navigate('/')
  }

  goBack = () => {
    if (this.formSchema.length) {
      Router.navigate('/simple-parcel-list/:opCode', { opCode: this.operation.code })
    } else {
      this.goDashboard()
    }
  }

  onClear = async () => {
    RfidReader.clear()
    this.setState({
      items: [],
      ignoredEpcs: [],
      identifierCodesByParcel: {},
      parcelsByAsn: {},
      unexpectedDecodedParcels: {},
    })
    if (this.locationState.tags && this.locationState.tags.length > 0) {
      RfidReader.emulateTags(this.locationState.tags?.map((tag) => tag.epc) ?? [])
    }
  }

  /** La conferma è abilitata se c'è almeno un detected e nessun unexpected */
  showConfirmButton() {
    const { confirming } = this.state
    if (this.getDetected().length > 0 && this.getUnexpected().length === 0)
      return <Button loading={confirming} disabled={confirming} title={__(T.misc.confirm)} onClick={this.onConfirm} />
    return null
  }

  removeParcel(parcel: ShippingParcel) {
    try {
      const { parcelsByAsn, identifierCodesByParcel } = this.state
      const asnCode = Object.keys(parcelsByAsn).find((key) => parcelsByAsn[key].some((p) => p.id === parcel.id))

      if (!asnCode && asnCode !== '') throw new Error('Invalid parcel to remove')

      RfidReader.removeTags(identifierCodesByParcel[parcel.id])
      delete identifierCodesByParcel[parcel.id]

      const newAsnParcels = [...parcelsByAsn[asnCode]]
      const idx = newAsnParcels.findIndex(({ id }) => id === parcel.id)

      newAsnParcels[idx].__detected = false
      newAsnParcels[idx].__forced = false
      newAsnParcels[idx].__quantityRead = 0

      this.setState({ parcelsByAsn: { ...parcelsByAsn, [asnCode]: newAsnParcels }, identifierCodesByParcel })
    } catch (error) {
      showToastError(error, __(T.error.error), this.isModal)
    }
  }

  openIgnoredEpcs = () => {
    const { ignoredEpcs } = this.state
    openModal({
      id: 'ignoredEpcs',
      modal: (
        <DecodedItemsModal
          decodedItems={ignoredEpcs.map((epc) => ({ identifierCode: epc }))}
          operation={this.operation}
          isOpen
          onClose={() => closeModal('ignoredEpcs')}
          title="Epcs"
        />
      ),
    })
  }

  openUnexpectedParcel = () => {
    const { unexpectedDecodedParcels } = this.state
    const parcels: ShippingParcel[] = []
    Object.keys(unexpectedDecodedParcels).forEach((key) => {
      const parcelCode = unexpectedDecodedParcels[key].parcelCode ?? 'UNKNOWN'
      const index = parcels.findIndex((p) => p.code === parcelCode)
      if (index >= 0) {
        parcels[index].parcelEntryQuantity = parcels[index]?.parcelEntryQuantity
          ? parcels[index].parcelEntryQuantity ?? +1
          : (parcels[index].parcelEntryQuantity = 1)
      } else {
        parcels.push({
          code: parcelCode,
          parcelEntryQuantity: 1,
          attributes: {},
        } as any)
      }
    })
    openModal({
      id: 'unexpectedParcelsModal',
      modal: (
        <Modal
          title={__(T.misc.unexpected_parcel)}
          visible
          onClose={() => closeModal('unexpectedParcelsModal')}
          size="2xl"
          isCentered
        >
          <Box height="70vh">
            <GroupedParcelList operation={this.operation} parcels={parcels} />
          </Box>
        </Modal>
      ),
    })
  }

  removeAsn(asnCode: string) {
    const { parcelsByAsn, identifierCodesByParcel } = this.state
    const newParcelsByAsn = { ...parcelsByAsn }

    newParcelsByAsn[asnCode].forEach((parcel) => {
      RfidReader.removeTags(identifierCodesByParcel[parcel.id])
      delete identifierCodesByParcel[parcel.id]
    })

    delete newParcelsByAsn[asnCode]
    this.setState({ parcelsByAsn: newParcelsByAsn, identifierCodesByParcel })
  }

  render() {
    const { formData, loading, parcelsByAsn, unexpectedDecodedParcels, ignoredEpcs } = this.state
    const unexpectedCount = Object.keys(unexpectedDecodedParcels).length
    return (
      <Page
        title={this.operation.description}
        onBackPress={() => this.goBack()}
        loading={loading}
        header={{
          details: {
            data: _cloneDeep(formData),
            formSchema: this.formSchema,
            setFormData: async (fd) => {
              if (!(await SimpleParcelListExtensions.formDataIsValid(fd, this.operation, this.formSchema))) return
              this.setState({ formData: fd })
              this.setRfidReaderDecode()
            },
            resetFormData: async (fd) => {
              this.setState({ formData: fd })
            },
            operationId: this.operation.id,
          },
        }}
        enableEmulation
      >
        <Page.Sidebar>
          <Box flex style={{ overflowY: 'auto' }}>
            <TagCounter detected={this.getDetected().length} />
            <AntennaButton
              ref={this.antennaRef}
              onClear={this.onClear}
              decodeFunction={this.decodeFunction}
              hideClear={
                unexpectedCount === 0 &&
                this.getDetected().length === 0 &&
                ignoredEpcs.length === 0 &&
                // .filter((p) => p.__detected || p.__forced)
                Object.values(parcelsByAsn).flat().length === 0
              }
            />
            <Spacer />
            <Box row justify="space-evenly">
              {unexpectedCount > 0 && (
                <SmallTextCounter
                  onClick={this.openUnexpectedParcel}
                  status="warning"
                  title="Unexpected"
                  counter={unexpectedCount}
                />
              )}
              {unexpectedCount > 0 && ignoredEpcs.length > 0 && <Spacer />}
              {ignoredEpcs.length > 0 && (
                <SmallTextCounter
                  onClick={this.openIgnoredEpcs}
                  status="ignored"
                  title={__(T.misc.ignored)}
                  counter={ignoredEpcs.length}
                />
              )}
            </Box>
          </Box>
          {this.showConfirmButton()}
        </Page.Sidebar>
        <Page.Content notBoxed>
          {!this.asnMode ? (
            <GroupedParcelList
              operation={this.operation}
              parcels={Object.values(parcelsByAsn).flat()}
              removeParcel={(parcel) => this.removeParcel(parcel)}
              refresh={() => this.forceUpdate()}
            />
          ) : (
            <div>
              {Object.keys(parcelsByAsn).map((asnCode, sIdx) => (
                <>
                  <Section
                    key={sIdx}
                    header={
                      <Box
                        style={{
                          flex: 1,
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          alignSelf: 'center',
                          marginRight: 10,
                        }}
                      >
                        <Box
                          style={{
                            flexDirection: 'row',
                            alignSelf: 'center',
                            width: '100%',
                            height: '100%',
                            justifyContent: 'space-between',
                          }}
                        >
                          <p>{asnCode}</p>
                          <Button
                            onClick={() => this.removeAsn(asnCode)}
                            variant="secondary"
                            size="medium"
                            circle
                            padding={'0px'}
                          >
                            <Icons.Delete width={22} height={22} />
                          </Button>
                        </Box>
                      </Box>
                    }
                    body={
                      <div style={{ marginBottom: 15 }}>
                        {Object.values(parcelsByAsn[asnCode]).map((parcel, pIdx) => (
                          <div style={{ marginTop: 20 }}>
                            <GroupedParcelListItem
                              key={pIdx}
                              parcel={parcel}
                              overrideReadCounter={parcel.__quantityRead}
                              removeParcel={(p) => this.removeParcel(p)}
                            />
                          </div>
                        ))}
                      </div>
                    }
                  />
                </>
              ))}
            </div>
          )}
        </Page.Content>
      </Page>
    )
  }
}
