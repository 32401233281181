import {
  api,
  Auth,
  ConfirmModalParams,
  FeedbackModalParams,
  TmrPrinter,
  TmrUser,
  TmrWorkstation,
  Workstations,
  ApplicationSetting,
} from 'stylewhere/api'
import { ModalType } from 'stylewhere/types'
import { changeLanguage as changeLanguage_i18n, getLanguage as getLanguage_i18n, __ } from 'stylewhere/i18n'
import { __isDev, showToastError } from 'stylewhere/utils'
import { Storage, Router, RemoteOperation, keycloak } from 'stylewhere/shared'
import { ConnectionError } from 'stylewhere/shared/errors'
import { config } from 'stylewhere/config'
import { datadogRum } from '@datadog/browser-rum'
import { datadogLogs } from '@datadog/browser-logs'
import mixpanel from 'mixpanel-browser'

export interface Emulation {
  name: string
  value: string
}

class AppStore {
  authToken?: string
  defaultWorkstation?: TmrWorkstation
  defaultPrinter?: TmrPrinter
  workstations?: TmrWorkstation[]
  loggedUser?: TmrUser
  applicationSettings?: any[]
  language = 'it'
  languages = ['it', 'en']
  emulation = __isDev
  zbpEmulation = false
  encodedItems: { [operationCode: string]: { date?: string; counter?: number } } = {}
  dailyEncodedItems: { [operationCode: string]: number } = {}
  zbpSelectedPrinterUid?: string
  emulationList: Emulation[] = []
  emulationIdentifierType: 'NFC' | 'UHF' | 'SIMPLE_ITEM_IDENTIFIER' = 'UHF'
  keycloakEvent?: {
    eventType:
      | 'onReady'
      | 'onInitError'
      | 'onAuthSuccess'
      | 'onAuthError'
      | 'onAuthRefreshSuccess'
      | 'onAuthRefreshError'
      | 'onAuthLogout'
      | 'onTokenExpired'
    error?: { error: string; error_description: string }
  }

  callbacks: { [name: string]: any } = {}
  confirmModalParams: ConfirmModalParams = {
    title: '',
    message: '',
    onConfirm: () => {},
    onCancel: () => {},
    labelClose: undefined,
    labelConfirm: undefined,
    showDetailForProduct: undefined,
  }
  feedbackModalParam: FeedbackModalParams = {
    title: '',
    message: '',
    onCancel: () => {},
  }
  toggleConfirmModalHandler?: (show: boolean) => void
  toggleFeebackModalHandler?: (show: boolean) => void

  openModal?: (modal: ModalType) => void
  closeModal?: (id: string) => void

  reloadRouting!: () => void
  attachMixpanel = false

  async loadInitialData() {
    // check language
    this.language = getLanguage_i18n()

    //check if is present a custom endpoint
    api.setBaseURL(await Storage.load('endpoint', api.getBaseURL()))

    await this.loadAuthToken()
    if (this.authToken) {
      try {
        // load users and remote operations configurations in parallel
        this.loggedUser = await Auth.loggedUser()
        if (this.loggedUser) {
          await RemoteOperation.load()
          this.applicationSettings = await ApplicationSetting.client()
          this.initiliazeDataDog()
          if (this.hasAnyCapabilities('Resource.Workstation.view')) {
            const wsResponse = await Workstations.searchByPlaceId(this.loggedUser!.userPlaces.map((p) => p.id))
            this.workstations = wsResponse.content
            if (this.workstations && this.workstations.length === 1) {
              await this.saveDefaultWorkstation(this.workstations[0])
            } else if (this.workstations && this.workstations.length > 1) {
              const dWorkstation = await this.getDefaultWorkstation()
              this.defaultWorkstation = this.workstations.find((w) => w.id === dWorkstation?.id)
            }
          } else {
            showToastError(__('messages.no_workstations_found'))
          }

          await this.initializeMixpanel()
        }
        this.defaultPrinter = await this.getDefaultPrinter()
        this.dailyEncodedItems = await Storage.load(`dailyEncodedItems`, {})
        if (typeof this.dailyEncodedItems !== 'object') this.dailyEncodedItems = {}

        this.encodedItems = await Storage.load(`encodedItems`, {})
        if (typeof this.dailyEncodedItems !== 'object') this.encodedItems = {}

        this.emulation = await Storage.load(`emulation`, false)
        this.emulationList = await this.loadEmulationList()
        this.emulationIdentifierType = await this.loadEmulationIdentifierType()
        this.zbpEmulation = await Storage.load(`zbpEmulation`, false)
      } catch (err) {
        if (err instanceof ConnectionError) {
          Router.navigate('/connection-error')
        } else {
          console.error({ err })
          showToastError(err)
          // throw err
        }
      }
    }
  }

  isAdmin = (): boolean => {
    if (this.loggedUser) {
      const userCapabilities = this.loggedUser.capabilities
      if (
        userCapabilities &&
        userCapabilities.length > 0 &&
        userCapabilities.includes('System.Administration.perform')
      ) {
        return true
      }
    }
    return false
  }

  hasAnyCapabilities = (capabilities: string | string[] | undefined): boolean => {
    if (typeof capabilities === 'string') {
      capabilities = [capabilities]
    }

    if (!this.loggedUser) {
      return false
    }

    if (!capabilities || capabilities.length === 0) {
      return true
    }

    const userCapabilities = this.loggedUser.capabilities
    if (!userCapabilities || userCapabilities!.length === 0) {
      return false
    }

    if (userCapabilities.includes('System.Administration.perform')) {
      return true
    }

    return capabilities.some((capability) => userCapabilities.includes(capability))
  }

  getWithTurnSequentialAntennas() {
    if (this.applicationSettings) {
      const setting = this.applicationSettings.find((element) => {
        return element.code === 'useSequentialAntennasTurn'
      })
      if (setting) {
        return setting.value === 'True'
      }
    }
    return false
  }

  getShowProductImageSettings() {
    let value = 'False'
    if (this.applicationSettings) {
      const setting = this.applicationSettings.find((element) => {
        return element.code === 'showProductImage'
      })
      if (setting) {
        value = setting.value
      }
    }
    return value
  }

  getShowProductImage() {
    const value = this.getShowProductImageSettings()
    return value.toLowerCase() === 'yes' || value === 'StylewherePrivateUrl' || value === 'PublicUrl'
  }

  getUserSelfInitializationPolicy() {
    let policy = 'none'
    if (this.applicationSettings) {
      const setting = this.applicationSettings.find((element) => {
        return element.code === 'userSelfInitializationPolicy'
      })
      if (setting) {
        policy = setting.value
      }
    }
    return policy
  }

  canEditUserSelf() {
    let can = false
    if (this.loggedUser) {
      can = this.loggedUser.userPlaces.length === 0
      if (can && this.getUserSelfInitializationPolicy() === 'none') can = false
    }
    return can
  }

  async getEndpoint() {
    return Storage.load('endpoint', api.getBaseURL())
  }

  async changeLanguage(language: string) {
    this.language = language
    changeLanguage_i18n(language)
  }

  async setEndpoint(endpoint) {
    await Storage.save('endpoint', endpoint)
    Router.reloadApp()
  }

  getEmulation() {
    return this.emulation
  }

  async enableEmulation(emulation = true) {
    this.emulation = emulation
    await Storage.save(`emulation`, emulation)
  }

  async enableZbpEmulation(emulation = true) {
    this.zbpEmulation = emulation
    await Storage.save(`zbpEmulation`, emulation)
  }

  getCurrentData() {
    const current = new Date()
    const date = current.getDate() + '-' + (current.getMonth() + 1) + '-' + current.getFullYear()
    return date
  }

  async checkEncodedItems(operationCode: string) {
    const data = this.encodedItems[operationCode] || {}
    const date: string = this.getCurrentData()
    if (!data.date || data.date !== date) {
      this.encodedItems[operationCode] = { date: date, counter: 0 }
      await Storage.save(`encodedItems`, this.encodedItems)
    }
  }

  async increaseEncodedItems(operationCode: string) {
    const data = this.encodedItems[operationCode] || {}
    const date: string = this.getCurrentData()
    if (data.date && data.counter && data.date === date) {
      this.encodedItems[operationCode] = { date: date, counter: data.counter + 1 }
    } else {
      this.encodedItems[operationCode] = { date: date, counter: 1 }
    }
    await Storage.save(`encodedItems`, this.encodedItems)
  }

  async getEncodedItems(operationCode: string) {
    const data = this.encodedItems[operationCode] || {}
    return data.counter || 0
  }

  async increaseDailyEncodedItems(operationCode: string) {
    this.dailyEncodedItems[operationCode] = (this.dailyEncodedItems[operationCode] || 0) + 1
    await Storage.save(`dailyEncodedItems`, this.dailyEncodedItems)
  }

  async resetDailyEncodedItems(operationCode: string) {
    this.dailyEncodedItems[operationCode] = 0
    await Storage.save(`dailyEncodedItems`, this.dailyEncodedItems)
  }

  async getZbpSelectedPrinterUid() {
    this.zbpSelectedPrinterUid = await Storage.load(`zbpSelectedPrinterUid`, this.zbpSelectedPrinterUid)
    return this.zbpSelectedPrinterUid
  }

  async setZbpSelectedPrinterUid(uid: string) {
    this.zbpSelectedPrinterUid = uid
    await Storage.save(`zbpSelectedPrinterUid`, uid)
    return this.zbpSelectedPrinterUid
  }

  async saveDefaultWorkstation(workstation: TmrWorkstation) {
    this.defaultWorkstation = workstation
    await Storage.save(`defaultWorkstation`, workstation)
    this.sendMixPanelUser()
    return this.defaultWorkstation
  }

  async getDefaultWorkstation() {
    this.defaultWorkstation = await Storage.load(`defaultWorkstation`, this.defaultWorkstation)
    return this.defaultWorkstation
  }

  async saveDefaultPrinter(printer: TmrPrinter) {
    this.defaultPrinter = printer
    await Storage.save(`defaultPrinter`, printer)
    return this.defaultPrinter
  }

  async getDefaultPrinter() {
    this.defaultPrinter = await Storage.load(`defaultPrinter`, this.defaultPrinter)
    return this.defaultPrinter
  }

  async saveAuthToken(token?: string) {
    this.authToken = token
    await Storage.save(`authToken`, token)
    return this.authToken
  }

  async logout() {
    this.destroyDataDog()
    Storage.remove(`authToken`)
    this.authToken = undefined
    Storage.remove(`defaultWorkstation`)
    this.defaultWorkstation = undefined
    Storage.remove(`defaultPrinter`)
    this.defaultPrinter = undefined
    Storage.remove(`dailyEncodedItems`)
    Storage.remove(`emulation`)
    this.loggedUser = undefined
    this.attachMixpanel = false
    await keycloak.logout()
    if (this.reloadRouting) this.reloadRouting()
    else Router.reloadApp()
  }

  async loadAuthToken() {
    if (keycloak.token) {
      Auth.setHeaderAccessToken(keycloak.token)
      this.authToken = keycloak.token
    }

    return this.authToken
  }

  async saveEmulationIdentifierType(emulationIdentifierType: 'UHF' | 'SIMPLE_ITEM_IDENTIFIER' | 'NFC') {
    this.emulationIdentifierType = emulationIdentifierType
    await Storage.save(`emulationIdentifierType`, emulationIdentifierType)
    return this.emulationIdentifierType
  }

  async loadEmulationIdentifierType() {
    this.emulationIdentifierType = await Storage.load(`emulationIdentifierType`, this.emulationIdentifierType)
    return this.emulationIdentifierType
  }

  async saveEmulationList(emulationList: Emulation[]) {
    this.emulationList = emulationList
    await Storage.save(`emulationList`, emulationList)
    return this.emulationList
  }

  async loadEmulationList() {
    this.emulationList = await Storage.load(`emulationList`, this.emulationList)
    return this.emulationList
  }

  async getAdminMode() {
    return Storage.load('adminMode', false)
  }

  async setAdminMode(value) {
    await Storage.save('adminMode', value)
  }

  setConfirmModalHandler(handler) {
    this.toggleConfirmModalHandler = handler
  }

  showConfirmModal(
    title: string,
    message: string,
    onConfirm: () => void,
    onCancel?: () => void,
    labelClose?: string,
    labelConfirm?: string,
    showDetailForProduct?: ConfirmModalParams['showDetailForProduct']
  ) {
    this.confirmModalParams = {
      title,
      message,
      onConfirm,
      onCancel: onCancel ?? undefined,
      labelClose: labelClose ?? undefined,
      labelConfirm: labelConfirm ?? undefined,
      showDetailForProduct: showDetailForProduct ?? undefined,
    }
    this.toggleConfirmModalHandler?.(true)
  }

  hideConfirmModal() {
    this.toggleConfirmModalHandler?.(false)
  }

  showFeedbackModal(title: string, message: string, onCancel: () => void, btnCloseModal?) {
    this.feedbackModalParam = {
      title,
      message,
      onCancel: onCancel,
      btnCloseModal: btnCloseModal,
    }
    this.toggleFeebackModalHandler?.(true)
  }

  hideFeedbackModal() {
    this.toggleFeebackModalHandler?.(false)
  }

  getDataDogApplicationSettings = async (code, defValue) => {
    let value = defValue
    if (this.applicationSettings) {
      const setting = this.applicationSettings.find((element) => {
        return element.code === code
      })
      if (setting) {
        value = setting.value
      }
    }
    return value
  }

  activeDatadog = async () => {
    const enabled = await this.getDataDogApplicationSettings('dataDogEnabled', 'False')
    return (
      this.loggedUser &&
      enabled.toLowerCase() === 'true' &&
      config.dataDogApplicationID !== '' &&
      config.dataDogClientToken !== '' &&
      config.dataDogSite !== '' &&
      config.dataDogService !== '' &&
      config.dataDogEnv !== ''
    )
  }

  initiliazeDataDog = async () => {
    const isActive = await this.activeDatadog()
    if (isActive) {
      const dataDogSessionSampleRate = await this.getDataDogApplicationSettings(
        'dataDogSessionSampleRate',
        config.dataDogSessionSampleRate
      )
      const dataDogSessionReplaySampleRate = await this.getDataDogApplicationSettings(
        'dataDogSessionReplaySampleRate',
        config.dataDogSessionReplaySampleRate
      )
      const dataDogTrackResources = await this.getDataDogApplicationSettings(
        'dataDogTrackResources',
        config.dataDogTrackResources ? 'True' : 'False'
      )
      const dataDogTrackLongTasks = await this.getDataDogApplicationSettings(
        'dataDogTrackLongTasks',
        config.dataDogTrackLongTasks ? 'True' : 'False'
      )
      const dataDogTrackUserInteractions = await this.getDataDogApplicationSettings(
        'dataDogTrackUserInteractions',
        config.dataDogTrackUserInteractions ? 'True' : 'False'
      )
      datadogRum.init({
        applicationId: config.dataDogApplicationID,
        clientToken: config.dataDogClientToken,
        site: config.dataDogSite,
        service: config.dataDogService,
        env: config.dataDogEnv,
        // version: '1.0.0',
        sessionSampleRate: parseFloat(dataDogSessionSampleRate + ''),
        sessionReplaySampleRate: parseFloat(dataDogSessionReplaySampleRate + ''),
        trackUserInteractions: dataDogTrackUserInteractions.toLowerCase() === 'true',
        trackResources: dataDogTrackResources.toLowerCase() === 'true',
        trackLongTasks: dataDogTrackLongTasks.toLowerCase() === 'true',
        defaultPrivacyLevel: 'mask-user-input',
      })

      datadogRum.startSessionReplayRecording()

      // initialize datadogLogs
      datadogLogs.init({
        clientToken: config.dataDogClientToken,
        service: config.dataDogService,
        site: config.dataDogSite,
        env: config.dataDogEnv,
        forwardErrorsToLogs: true,
        sessionSampleRate: parseFloat(dataDogSessionSampleRate + ''),
      })

      if (this.loggedUser) {
        datadogRum.setUser({ id: this.loggedUser.id, name: this.loggedUser.name, email: this.loggedUser.email })
      }
    }
  }

  sendDataDogLog = (label, param) => {
    datadogLogs.logger.info(label, param)
  }

  destroyDataDog = async () => {
    const isActive = await this.activeDatadog()
    if (isActive) {
      datadogRum.stopSessionReplayRecording()
    }
  }

  initializeMixpanel = async () => {
    const enabled = await this.getDataDogApplicationSettings('enableMixpanelTracking', 'true')
    if (enabled.toLowerCase() === 'true' && process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN && this.loggedUser) {
      this.attachMixpanel = true
      mixpanel.init(process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN)
      mixpanel.identify(this.loggedUser.id)
      this.sendMixPanelUser()
    }
  }

  getWorkstationPlaceCode = () => {
    let placeCode = ''
    if (this.defaultWorkstation && this.defaultWorkstation.placeId && this.loggedUser) {
      const place = this.loggedUser.userPlaces.find((el) => el.id === this.defaultWorkstation?.placeId || '')
      if (place) placeCode = place.code
    }
    return placeCode
  }

  sendMixPanelUser = () => {
    if (this.attachMixpanel && this.loggedUser) {
      const placeCode = this.getWorkstationPlaceCode()
      const user = {
        name: this.loggedUser.name ?? '',
        surname: this.loggedUser.surname ?? '',
        username: this.loggedUser.username ?? '',
        email: this.loggedUser.email ?? '',
        workstation: this.defaultWorkstation ? this.defaultWorkstation.code : '',
        place: placeCode,
      }
      mixpanel.people.set(user)
    }
  }

  sendMixPanelEvent = (name, data) => {
    if (this.attachMixpanel) {
      mixpanel.track(name, data)
    }
  }
}

export default new AppStore()
