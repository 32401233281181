import { api, responseErrorCheck } from './api'
import { EncodingValidationRequest, EncodingValidationResponse, EncodingCounters, Product, FindProductRequest } from 'stylewhere/api'

export class Encodings {
  static endpoint = 'api/v1/operations/encoding' //encoding

  static async validate(validation: EncodingValidationRequest) {
    return api
      .post<EncodingValidationResponse>(`${this.endpoint}/validate`, validation)
      .then((res) => responseErrorCheck(res))
  }

  static async create(validation: EncodingValidationRequest) {
    return api
      .post<EncodingValidationResponse>(`${this.endpoint}/create`, validation)
      .then((res) => responseErrorCheck(res))
  }

  static async force_create(validation: EncodingValidationRequest, pin?: string) {
    const queryparams = pin && pin !== '' ? '?pin=' + pin : ''
    return api
      .post<EncodingValidationResponse>(`${this.endpoint}/create/force` + queryparams, validation)
      .then((res) => responseErrorCheck(res))
  }

  static async verify(validation: EncodingValidationRequest) {
    return api
      .post<EncodingValidationResponse>(`${this.endpoint}/verify`, validation)
      .then((res) => responseErrorCheck(res))
  }

  static async getProductionOrderRowCounter(productionOrderRowId: string) {
    return api.get<EncodingCounters>(`${this.endpoint}/counters?productionOrderRowId=${productionOrderRowId}`)
  }

  static async disassociate(body: { operationId: string; itemId: string }) {
    return api.post<EncodingCounters>(`${this.endpoint}/disassociate`, body).then((res) => responseErrorCheck(res))
  }

  static async findProduct(findProductRequest: FindProductRequest) {
    return api.post<Product>(`${this.endpoint}/findProduct`, findProductRequest).then(res => res)
  }

  static async computeTags(
    operationId: string,
    identifiers: any[],
    workstationId: string,
    startEntityId?: string,
    startEntityCode?: string,
    operationAttributes?,
    itemAttributes?
  ) {
    const body: any = {
      operationId: operationId,
      identifiers: identifiers,
      workstationId: workstationId,
      operationAttributes: operationAttributes ?? {},
      itemAttributes: itemAttributes ?? {},
    }
    if (startEntityId) body.startEntityId = startEntityId
    if (startEntityCode) body.startCode = startEntityCode
    return api.post<any>(`${this.endpoint}/computeTags`, body).then((res) => responseErrorCheck(res))
  }

  static async executeAction(
    operationId: string,
    action: string,
    vblParcelCode: string,
    wam: string,
    workstationId: string
  ) {
    const decodePayload = {
      operationId: operationId,
      actionCode: action,
      attributes: {
        vblParcelCode: vblParcelCode,
        wam: wam,
        workstationId: workstationId,
      },
    }
    const res = await api.post(`${this.endpoint}/${operationId}/executeAction `, decodePayload)
    return responseErrorCheck(res)
  }

  static async findOrder(operationId: string, wam: string) {
    const decodePayload = {
      operationId: operationId,
      code: wam,
    }
    return api
      .post<EncodingCounters>(`${this.endpoint}/findOrder`, decodePayload)
      .then((res) => responseErrorCheck(res))
  }
}
